import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo from '../images/mike-martin-law-logo-2x.png';
import { Link } from 'gatsby';
import * as ROUTES from '../constants/routes';

const navigation = [
    { name: 'Estate Planning', href: ROUTES.ESTATES },
    { name: 'Probate', href: ROUTES.PROBATE },
    { name: 'Trusts', href: ROUTES.TRUSTS },
    { name: 'Business', href: ROUTES.BUSINESS },
    { name: 'About', href: ROUTES.ABOUT },
    { name: 'Contact', href: ROUTES.CONTACT },
]

const Nav = () => {
    return (
        <Disclosure as="header" className="bg-primary text-white mt-8">
        {({ open }) => (
            <>
                <div className="max-w-6xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-800 lg:px-8">
                    <div className="relative h-16 flex justify-between">
                    <div className="relative z-10 px-2 flex lg:px-0">
                        <div className="flex-shrink-0 flex items-center">
                        <Link to={ROUTES.INDEX}>
                            <img
                                className="block h-24 w-auto"
                                src={logo}
                                alt="Workflow"
                            />
                        </Link>
                        </div>
                    </div>
                    <div className="relative z-10 flex items-center lg:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Open menu</span>
                            {open ? (
                                <XIcon className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                            )}
                        </Disclosure.Button>
                    </div>
                    <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                        <div className="flex text-white text-xs gap-x-4 justify-center">
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                </svg>
                                <a href="tel:9133628560" className="ml-2">(913) 362-8560</a>
                            </div>
                            <div className="border border-gray-800 mx-2 w-px h-8/12"></div>
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                                <a 
                                    href="mailto:info@mikemartinlaw.com"
                                    className="ml-2"
                                >
                                    info@mikemartinlaw.com
                                </a>
                            </div>
                        </div>
                    </div>
                    </div>
                    <nav className="hidden lg:py-2 lg:flex lg:space-x-4" aria-label="Global">
                    {navigation.map((item) => (
                        <Link
                            key={item.name}
                            to={item.href}
                            activeClassName="active"
                            className="text-white hover:border-b hover:border-secondary hover:text-gray-200 py-2 px-3 inline-flex items-center font-medium"
                        >
                        {item.name}
                        </Link>
                    ))}
                    </nav>
                    <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
                        <div className="pt-2 pb-3 px-2 space-y-1">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className="text-white text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md py-2 px-3 text-base font-medium"
                                >
                                    {item.name}
                                </a>
                            ))}
                            <div className="border-b border-gray-800 my-4"></div>
                            <div className="py-2 px-3">
                                <a href="tel:9133628560">(913) 362-8560</a>
                            </div>
                            <div className="py-2 px-3">
                                <a 
                                    href="mailto:info@mikemartinlaw.com"
                                >
                                    info@mikemartinlaw.com
                                </a>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </div>
            </>
        )}
        </Disclosure>
    )
}

export default Nav;
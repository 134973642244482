import React from 'react';
import { Link } from 'gatsby';
import * as ROUTES from '../constants/routes';

const navigation = [
    { name: 'Estate Planning', href: ROUTES.ESTATES, current: true },
    { name: 'Probate', href: ROUTES.PROBATE, current: false },
    { name: 'Trusts', href: ROUTES.TRUSTS, current: false },
    { name: 'Business', href: ROUTES.BUSINESS, current: false },
    { name: 'About', href: ROUTES.ABOUT, current: false },
    { name: 'Contact', href: ROUTES.CONTACT, current: false },
    { name: 'Legal Disclaimer', href: ROUTES.LEGAL, current: false },
]

const Footer = () => {
    const date = new Date();
    const year = date.getFullYear();
    return (
        <footer className="bg-primary">
            <div className="max-w-6xl mx-auto py-12 overflow-hidden ">
                <div className="border-t border-gray-800 mb-8"></div>
                <nav className="flex flex-wrap justify-start" aria-label="Footer">
                    {navigation.map((item) => (
                    <div key={item.name} className="px-5 py-2 leading-tight">
                        <Link 
                            to={item.href} 
                            activeClassName="active"
                            className="text-base text-white hover:text-gray-200 pb-2"
                        >
                            {item.name}
                        </Link>
                    </div>
                    ))}
                </nav>
                <p className="mt-8 text-left text-sm text-white px-5">&copy; {year} Mike Martin Law, Inc. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer;
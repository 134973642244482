import React from 'react';
import SEO from './SEO';
import Nav from '../components/Nav';
import Footer from '../components/Footer';

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <div className="flex flex-col min-h-screen bg-primary">
        <Nav />
        <main className="max-w-6xl mx-auto py-6 px-2 sm:px-4 w-full">
          {children}
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Layout

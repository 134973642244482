import React from 'react';

const Card = ({ children }) => {
    return (
        <div className="bg-white p-4 sm:p-8 rounded w-full">
            {children}
        </div>
    )
}

export default Card;
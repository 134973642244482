import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from 'gatsby';
import favicon from '../images/favicon.ico';

const SEO = ({ title, description, image }) => {
  const { pathname } = useLocation()
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          url
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;
  const url = `${defaults.url}${pathname}`;

  if (defaults.url === '' && typeof window !== 'undefined') {
    defaults.url = window.location.origin;
  }

  if (defaults.url === '') {
    console.error('Please set a url in your site metadata!');
    return null;
  }

  return (
    <Helmet>
      <title>{title ? title : defaults.title}</title>
      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      <link rel="canonical" href={url} />
      <meta name="description" content={description ? description : defaults.description} />
      {image && <meta name="image" content={image ? image : defaults.url} />}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title ? title : defaults.title} />
      <meta property="og:description" content={description ? description : defaults.description} />
      {image && <meta property="og:image" content={image ? image : defaults.image} />}
    </Helmet>
  );
};

export default SEO;